<template>
  <div>
    <p class="font-semibold mt-3">Number of players</p>
    <div class="flex items-center border p-3 mt-2">
      <p class="w-1/2">${{ price.toFixed(2) }} per player</p>
      <div class="flex justify-end items-center">
        <p class="border py-0.5 px-1 cursor-pointer" @click="decrease">
          <em class="fa-solid fa-minus"></em>
        </p>
        <input
          class="w-1/6 text-center border p-0.5 cursor-pointer"
          type="number"
          v-model="localPlayers"
          :min="1"
          @input="updatePlayers"
        />
        <p class="border py-0.5 px-1 cursor-pointer" @click="increase">
          <em class="fa-solid fa-plus"></em>
        </p>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, watch } from "vue";

const props = defineProps({
  players: {
    type: Number,
    default: 1,
  },
  price: {
    type: Number,
    default: 0,
  },
});
const emit = defineEmits(["update:players"]);

const localPlayers = ref(props.players);

function increase() {
  localPlayers.value++;
  emit("update:players", localPlayers.value);
}

function decrease() {
  if (localPlayers.value > 1) {
    localPlayers.value--;
    emit("update:players", localPlayers.value);
  }
}

function updatePlayers() {
  emit("update:players", localPlayers.value);
}

watch(
  () => props.players,
  (newVal) => {
    localPlayers.value = newVal;
  }
);
</script>
