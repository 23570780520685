import { ref } from "vue";
import stripe from "@/plugins/stripe";

export function useStripe() {
  const cardElement = ref(null);
  const cardObj = ref(null);
  const stripeValidationError = ref("");

  function initializateStripeElements() {
    const elements = stripe.elements();
    const style = {
      base: {
        iconColor: "black",
        color: "black",
        fontWeight: 500,
        fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
        fontSize: "16px",
        fontSmoothing: "antialiased",
        ":-webkit-autofill": {
          color: "#F7F9FB",
        },
      },
      invalid: {
        iconColor: "red",
        color: "red",
      },
    };

    cardObj.value = elements.create("card", { style });
    cardObj.value.mount(cardElement.value);
    cardObj.value.on("change", setValidationError);
  }

  async function createPaymentMethod(billingDetails) {
    try {
      const response = await stripe.createPaymentMethod({
        type: "card",
        card: cardObj.value,
        billing_details: billingDetails,
      });
      if (response.error) {
        stripeValidationError.value = response.error.message;
        return null;
      }
      return response.paymentMethod;
    } catch (error) {
      stripeValidationError.value = "Error creating payment method";
      return null
    }
  }

  function setValidationError(event) {
    stripeValidationError.value = event.error ? event.error.message : "";
  }

  return {
    cardElement,
    cardObj,
    stripeValidationError,
    initializateStripeElements,
    createPaymentMethod,
  };
}
